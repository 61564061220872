import _postcssValueParser3 from "postcss-value-parser";
import _parser2 from "./parser";
import _reducer3 from "./lib/reducer";
import _stringifier3 from "./lib/stringifier";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _postcssValueParser = _postcssValueParser3;

var _postcssValueParser2 = _interopRequireDefault(_postcssValueParser);

var _parser = _parser2;
var _reducer = _reducer3;

var _reducer2 = _interopRequireDefault(_reducer);

var _stringifier = _stringifier3;

var _stringifier2 = _interopRequireDefault(_stringifier);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
} // eslint-disable-line


var MATCH_CALC = /((?:\-[a-z]+\-)?calc)/;

exports.default = function (value) {
  var precision = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 5;
  return (0, _postcssValueParser2.default)(value).walk(function (node) {
    // skip anything which isn't a calc() function
    if (node.type !== "function" || !MATCH_CALC.test(node.value)) return; // stringify calc expression and produce an AST

    var contents = _postcssValueParser2.default.stringify(node.nodes); // skip constant() and env()


    if (contents.indexOf("constant") >= 0 || contents.indexOf("env") >= 0) return;

    var ast = _parser.parser.parse(contents); // reduce AST to its simplest form, that is, either to a single value
    // or a simplified calc expression


    var reducedAst = (0, _reducer2.default)(ast, precision); // stringify AST and write it back

    node.type = "word";
    node.value = (0, _stringifier2.default)(node.value, reducedAst, precision);
  }, true).toString();
};

exports = exports["default"];
export default exports;
export const __esModule = exports.__esModule;